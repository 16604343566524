@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.hovering-anim-box {
  height: 200px;
  width: 200px;
  position: fixed;
  left: 50%;
  z-index: 1001;
}
.running-corgi {
  position: fixed;
  bottom: -20px; /* Adjust the distance from the bottom */
  left: -200px; /* Start off-screen */
  height: 200px;
  width: 200px;
  z-index: 800;
  animation: slide 3.1s linear; /* Slide animation */
}

@keyframes slide {
  0% {
    left: 100vw; /* End off-screen to the right */
  }
  100% {
    left: -200px; /* Start off-screen to the left */
  }
}
